import React from 'react'
// import { Link } from "react-router-dom";

const WhoWeAre = () => {
	return (
		<>
			<section className='who'>
				<div
					className='textD'
					data-aos='fade-up'
					data-aos-offset='100'
					data-aos-delay='40'
					data-aos-duration='600'
					data-aos-easing='ease-in-out'
				>
					<h5> Introducing ThriftMs </h5>
			 
					<h3>
						ThriftMS offers a tailored software solution designed specifically for Cooperative Societies, streamlining essential activities such as savings, loans, and more.
					</h3>

					<p>{/*<Link to='#'>read more +</Link>*/}</p>
				</div>

				<article
					data-aos='fade-up'
					data-aos-offset='150'
					data-aos-delay='40'
					data-aos-duration='600'
					data-aos-easing='ease-in-out'
				>
					<p>
						We caters to both small-scale societies, with fewer than 50 members, and larger-scale ones exceeding 50 members. Individuals seeking to manage their monthly, quarterly, or yearly savings and associated services are also welcomed to leverage our platform.
					</p>
				</article>

				{/* <div className='exp'>
					<div className='expWrap'>
						<h3
							data-aos='fade-up'
							data-aos-offset='150'
							data-aos-delay='40'
							data-aos-duration='600'
							data-aos-easing='ease-in-out'
						>
							<span>160+</span>
							<p>professionals</p>
						</h3>

						<h3
							data-aos='fade-up'
							data-aos-offset='150'
							data-aos-delay='40'
							data-aos-duration='600'
							data-aos-easing='ease-in-out'
						>
							<span>5+</span>
							<p>years of average experience</p>
						</h3>

						<h3
							data-aos='fade-up'
							data-aos-offset='150'
							data-aos-delay='40'
							data-aos-duration='600'
							data-aos-easing='ease-in-out'
						>
							<span>100M+</span>
							<p>investments received clients</p>
						</h3>
					</div>
				</div> */}
			</section>
		</>
	)
}

export default WhoWeAre
