import React from 'react'
import logo from '../assets/img/logo.png'


const Footer = () => {
	const currentDate = new Date();

    // Get the current year from the Date object
    const currentYear = currentDate.getFullYear();
	return (
		<>
			<footer>
				<div className='footWrap'>
					<div className='headF'>
					<img src={logo} alt='logo' height={50} width={150}/>
					</div>

			{/* 		<ul className='uList'>
						<li>
							<a href='http://'>about</a>
						</li>
						<li>
							<a href='http://'>product</a>
						</li>
						<li>
							<a href='http://'>blog</a>
						</li>
						<li>
							<a href='http://'>careers</a>
						</li>
						<li>
							<a href='http://'>support</a>
						</li>
					</ul> */}

					<ul className='ulSocial'>
						<li>
							<a href='http://'>
								<ion-icon name='logo-instagram'></ion-icon>
							</a>
						</li>
						<li>
							<a href='http://'>
								<ion-icon name='logo-twitter'></ion-icon>
							</a>
						</li>
						<li>
							<a href='http://'>
								<ion-icon name='logo-whatsapp'></ion-icon>
							</a>
						</li>
						<li>
							<a href='http://'>
								<ion-icon name='logo-github'></ion-icon>
							</a>
						</li>
						<li>
							<a href='http://'>
								<ion-icon name='logo-linkedin'></ion-icon>
							</a>
						</li>
					</ul>

					<div className='caption'>
						<h3>Hello from Us. 🏡</h3>
						<p>© {currentYear} View our Privacy Policy and Terms of Service.</p>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer
