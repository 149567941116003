import React from 'react'
import {Link} from "react-router-dom";

const GetInTouch = () => {
	return (
		<>
			<section className='cta'>
				<div
					className='textD'
					data-aos='fade-up'
					data-aos-offset='100'
					data-aos-delay='40'
					data-aos-duration='600'
					data-aos-easing='ease-in-out'
				>
					<h5>Need a quote?</h5>
					<h3>Say Hello</h3>
				</div>
				<div
					className='ctaWrap'
					data-aos='fade-up'
					data-aos-offset='100'
					data-aos-delay='40'
					data-aos-duration='800'
					data-aos-easing='ease-in-out'
				>
					<p>
						Contact us to find out how we can help you. If it’s easier you can email
						us at
						{/*<Link to='mailto:info@deesuntech.com'>info@deesuntech.com</Link> */}
						or call
						{/*<Link to='tel:+2348149388249'>(+234) 08149388249.</Link> */}
						Download the
						{/*<Link to='#' download="ThriftMS Brochure">*/}
						{/*	ThriftMS Brochure.pdf*/}
						{/*</Link>*/}
						(5mb).
					</p>
				</div>
			</section>
		</>
	)
}

export default GetInTouch
