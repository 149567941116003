import React from 'react'
import logo from '../assets/img/logo.png'

const Nav = () => {
	return (
		<>
			<nav>
				<div className='navInner'>
					<div className='logo animate__fadeInLeft'>					
						<img src={logo} alt='logo' height={50} width={150}/>
					</div>

					<div className='menuWrap animate__fadeInRight'>
						<div className='cageMenu'>
							<div className='hamburger'>
								<span className='span1'></span>
								<span className='span2'></span>
							</div>

							<h3 className='menuT'>Menu</h3>
						</div>

						<div className='_smallNav'>
							<h1 className='menuH'>
								Learn more <a href='http://'>about us</a>, check out
								<a href='http://'>our work</a>, the latest on
								<a href='http://'>instagram</a> and <a href='http://'>contact us.</a>
							</h1>
						</div>
					</div>
				</div>
			</nav>
		</>
	)
}

export default Nav
