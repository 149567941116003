import React from 'react'
import banner from '../assets/img/tms_banner.png'
const Landing = () => {
	return (
		<>
			<section className='landing'>
				<div className='curveL'>
					<div className='curveT'>
						<h3 className='spanT'>
							<span className='animate__fadeInUp'> cooperatives</span>
							<span className='animate__fadeInUp'>management</span>
							<span className='animate__fadeInUp'> system</span>
						</h3>

					<p className='PT animate__fadeInUp'>
							We provide a scalable platform that merges seamless interaction between all members
							of a Cooperative Society and proper financing of each member.
							Enjoy well-structured thrift contribution, repay loans with ease.

						</p>

						{/*<p className='PT2 animate__fadeInUp'>*/}
						{/*	Check out our <a href='http://'>recent work</a> or learn more on*/}
						{/*	<a href='http://'>what we do.</a>*/}
						{/*</p>*/}
					</div><img src={banner} alt='banner' /> 

					{/* <div className='curveIMG'>
						<div className='imgBox'> 
							
						</div>
						<div className='attatch'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='h-5 w-5'
								viewBox='0 0 20 20'
								fill='currentColor'
							>
								<path
									fillRule='evenodd'
									d='M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
									clipRule='evenodd'
								/>
							</svg>
  
							<p>ThriftMS your No1 cooperative management system.</p>  
						</div>
					</div> */}
				</div>
			</section>
		</>
	)
}

export default Landing
