import React from 'react'

const Services = () => {
	return (
		<>
			<section className='services'>
				<div
					className='textD'
					data-aos='fade-up'
					data-aos-offset='100'
					data-aos-delay='40'
					data-aos-duration='600'
					data-aos-easing='ease-in-out'
				>
					<h5>Our Services</h5>
					<h3>What we offer.</h3>
				</div>

				<div className='service_s'>
					<div
						className='serve'
						data-aos='fade-up'
						data-aos-offset='100'
						data-aos-delay='40'
						data-aos-duration='800'
						data-aos-easing='ease-in-out'
					>
						<div className='headS'>
							<h2>Thrift management</h2>
							<span className='span_3'>
								<ion-icon name='chevron-down-outline'></ion-icon>
							</span>
						</div>

						<article className='article_3'>
							<p>
								Seamlessly manage both online and offline cash transactions within a single platform, ensuring all financial activities are tracked and accounted for regardless of the mode of transaction.
							</p>

							<div className='techIMG'>
								<img
									src='https://www.mindinventory.com/static/media/figma.b708b2af.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/xd.30776f24.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/zeplin.56322834.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/invision.df086033.webp'
									alt=''
								/>
							</div>
						</article>
					</div>

					<div
						className='serve'
						data-aos='fade-up'
						data-aos-offset='100'
						data-aos-delay='40'
						data-aos-duration='800'
						data-aos-easing='ease-in-out'
					>
						<div className='headS'>
							<h2>Efficient Account Management</h2>
							<span className='span_2'>
								<ion-icon name='chevron-down-outline'></ion-icon>
							</span>
						</div>

						<article className='article_2'>
							<p>
								ThriftMS allows users to create and manage accounts associated with their transactions. This feature enables users to easily track balances, monitor transaction histories, and manage account details for individuals or groups involved.
							</p>

							<div className='techIMG'>
								<img
									src='https://www.mindinventory.com/static/media/angular.eb050347.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/node.7ded16d6.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/php.e6ee094e.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/laravel.d9e956e7.webp'
									alt=''
								/>
							</div>
						</article>
					</div>

					<div
						className='serve'
						data-aos='fade-up'
						data-aos-offset='100'
						data-aos-delay='40'
						data-aos-duration='800'
						data-aos-easing='ease-in-out'
					>
						<div className='headS'>
							<h2>Insightful Analytics</h2>
							<span className='span_1'>
								<ion-icon name='chevron-down-outline'></ion-icon>
							</span>
						</div>

						<article className='article_1'>
							<p>
								With robust analytics tools, users can gain valuable insights into their financial activities. By analyzing transaction data and visualizing trends, users can make informed decisions to optimize their cash management strategies and achieve their financial goals more effectively.
							</p>

							<div className='techIMG'>
								<img
									src='https://www.mindinventory.com/static/media/react.4e28012a.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/android.4dc32be6.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/Ionic.be3d180c.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/android.4dc32be6.webp'
									alt=''
								/>
							</div>
						</article>
					</div>
					<div
						className='serve'
						data-aos='fade-up'
						data-aos-offset='100'
						data-aos-delay='40'
						data-aos-duration='800'
						data-aos-easing='ease-in-out'
					>
						<div className='headS'>
							<h2>Transparent Audit Logs</h2>
							<span className='span_1'>
								<ion-icon name='chevron-down-outline'></ion-icon>
							</span>
						</div>

						<article className='article_1'>
							<p>
								ThriftMS provides detailed audit logs that record all cash transactions and activities within the platform. This feature enhances transparency and accountability by capturing essential information such as transaction dates, amounts, parties involved, and any modifications made to transaction details.
							</p>

							<div className='techIMG'>
								<img
									src='https://www.mindinventory.com/static/media/react.4e28012a.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/android.4dc32be6.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/Ionic.be3d180c.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/android.4dc32be6.webp'
									alt=''
								/>
							</div>
						</article>
					</div>
				</div> 
			</section>
		</>
	)
}

export default Services
