import React, { useEffect } from 'react'
import './assets/main.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {
 /*  Blog,
  Display, */
  Footer,
  GetInTouch,
  Landing,
  MessageForm,
  Nav,
  Services,
  // Trending,
  WhoWeAre,
} from './components'

const App = () => {
  useEffect(() => {
    AOS.init()
  }, [])
  return (
      <>
        <main>
          <Nav />
          <MessageForm />
          <Landing />
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
            <path
                fill='#212529'
                fillOpacity='1'
                d='M0,192L34.3,213.3C68.6,235,137,277,206,250.7C274.3,224,343,128,411,80C480,32,549,32,617,69.3C685.7,107,754,181,823,213.3C891.4,245,960,235,1029,208C1097.1,181,1166,139,1234,128C1302.9,117,1371,139,1406,149.3L1440,160L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z'
            >

            </path>
          </svg>
          {/*<Display />*/}

          <WhoWeAre />
          {/* <Trending /> */}
          {<Services />}
          {/* <Blog /> */}
          <GetInTouch />
        {/*   <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
           <path
             fill='#212529'
               fillOpacity='1'
               d='M0,96L34.3,112C68.6,128,137,160,206,149.3C274.3,139,343,85,411,101.3C480,117,549,203,617,229.3C685.7,256,754,224,823,176C891.4,128,960,64,1029,85.3C1097.1,107,1166,213,1234,229.3C1302.9,245,1371,171,1406,133.3L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z'
           ></path>
        </svg>
         
          <div className='cookies'>
           <p>
            We use <a href='http://'>cookies</a> to enhance your experience.
          </p>
           <span className='got_it'>Got it</span>
          </div> */}
        </main>
        
        <Footer />
      </>
  )
}

export default App